<template>
    <section class="position-relative  feature-section ptb-100" v-bind:class="{ 'gray-light-bg' : isGray }">
        <div class="container">
            <div
                class="row align-items-center justify-content-between justify-content-sm-center justify-content-md-center">
                <div class="col-sm-5 col-md-6 col-lg-6 mb-5 mb-md-5 mb-sm-5 mb-lg-0">
                    <div class="download-img">
                        <img src="" alt="download" class="img-fluid" />
                    </div>
                </div>
                <div class="col-md-12 col-lg-6">
                    <div class="feature-contents">
                        <h2>BBR Gift Card has the following benefits:</h2>
                        <!--<p>
                            Objectively deliver professional value with diverse web-readiness.
                            Collaboratively transition wireless customer service without goal-oriented catalysts for
                            change. Collaboratively.
                        </p>
                        <p>Uniquely simplify sustainable applications whereas adaptive schemas. Competently brand performance based content and.</p>-->
                        <ul class="dot-circle pt-2">
                            <b>
                                Get 20% discount from BBR Shopping, 20% discount on BBR Food, get two free rides of BBR Taxi
                                in Kabul city (for a limit of AFN 250), and get two free Parcel Delivery as well. 
                            </b>
                            
                            <br><br>
                            
                            <b>How to get BBR Gift Card?</b>

                            <br><br>

                            <li>Ten orders from BBR Food</li>
                            <li>Ten orders from BBR Parce</li>
                            <li>Ten order from BBR Shopping (within AFN 10,000)</li>
                            <li>Ten rides from BBR Taxi</li>

                            
                            <b>NOTE</b> BBR Gift Card is valid just for one month and You must complete the above requirements within a month to get BBR Gift Card.
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>


<script>
    export default {
        name: 'SharePhotos',
        props: {
            isGray: {
                type: Boolean,
                default: false
            }
        }
    }
</script>