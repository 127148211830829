<template>
    <!--footer section start-->
    <!--when you want to remove subscribe newsletter container then you should remove .footer-with-newsletter class-->
    <footer class="footer ptb-60" v-bind:class="{ 'footer-with-newsletter': showSubscribe}">
        <!--subscribe newsletter start-->
        <div class="container" v-show="showSubscribe">
            <div class="row newsletter-wrap primary-bg rounded shadow-lg p-5">
                <div class="col-md-6 col-lg-7 mb-4 mb-md-0 mb-sm-4 mb-lg-0">
                    <div class="newsletter-content text-white">
                        <h3 class="mb-0 text-white">Let's get close!</h3>
                        <p class="mb-0">Follow us on our social media pages, get daily updates and <br> never miss our great offers.</p>
                    </div>
                </div>
                <div class="col-md-6 col-lg-5 m-auto">
                    <div class="soci-icons">
                        <a href="">
                            <span>
                                <i class="fab fa-facebook iconi"></i>
                            </span>
                        </a>
                        <a href="">
                            <span>
                                <i class="fab fa-instagram iconi"></i>
                            </span>
                        </a>
                        <a href="">
                            <span>
                                <i class="fab fa-twitter iconi"></i>
                            </span>
                        </a>
                        <a href="">
                            <span>
                                <i class="fab fa-youtube iconi"></i>
                            </span>
                        </a>
                        <a href="">
                            <span>
                                <i class="fab fa-linkedin-in iconi"></i>
                            </span>
                        </a>
                        <a href="">
                            <span>
                                <i class="fab fa-whatsapp iconi"></i>
                            </span>
                        </a>
                    </div>
                </div>
            </div>
        </div>
        <!--subscribe newsletter end-->

        <div class="container">
            <div class="row">
                <div class="col-md-12 col-lg-4 mb-4 mb-md-4 mb-sm-4 mb-lg-0">
                    <a href="#" class="navbar-brand mb-2">
                        <img src="../../assets/img/bbr-img/logo-2.png" alt="logo" class="img-fluid" />
                    </a>
                    <br />
                    <!-- <div class="list-inline social-list-default background-color social-hover-2 mt-2">
                        <li class="list-inline-item">
                            <a class="twitter" href="#">
                                <i class="fab fa-twitter"></i>
                            </a>
                        </li>
                        <li class="list-inline-item">
                            <a class="youtube" href="#">
                                <i class="fab fa-youtube"></i>
                            </a>
                        </li>
                        <li class="list-inline-item">
                            <a class="linkedin" href="#">
                                <i class="fab fa-linkedin-in"></i>
                            </a>
                        </li>
                        <li class="list-inline-item">
                            <a class="dribbble" href="#">
                                <i class="fab fa-dribbble"></i>
                            </a>
                        </li>
                    </div> -->
                </div>
                <div class="col-md-12 col-lg-8">
                    <div class="row mt-0">
                        <div class="col-sm-6 col-md-2 col-lg-2 mb-4 mb-sm-4 mb-md-0 mb-lg-0" style="visibility:hidden">
                            <h6 class="text-uppercase">Products</h6>
                            <ul>
                                <li>
                                    <a href="#">Taxi</a>
                                </li>
                                <li>
                                    <a href="#">Food</a>
                                </li>
                                <li>
                                    <a href="#">Parcel</a>
                                </li>
                                <li>
                                    <a href="#">Rent a Car</a>
                                </li>
                                <li>
                                    <a href="#">Shopping</a>
                                </li>
                            </ul>
                        </div>
                        <div class="col-sm-6 col-md-4 col-lg-4 mb-4 mb-sm-4 mb-md-0 mb-lg-0">
                            <h6 class="text-uppercase">Products</h6>
                            <ul>
                                <li>
                                    <a href="#">Taxi</a>
                                </li>
                                <li>
                                    <a href="#">Food</a>
                                </li>
                                <li>
                                    <a href="#">Parcel</a>
                                </li>
                                <li>
                                    <a href="#">Rent a Car</a>
                                </li>
                                <li>
                                    <a href="#">Shopping</a>
                                </li>
                            </ul>
                        </div>
                        <div class="col-sm-6 col-md-4 col-lg-4">
                            <h6 class="text-uppercase">Join us</h6>
                            <ul>
                                <li>
                                    <a href="index-6">Become a Merchant</a>
                                </li>
                                <li>
                                    <a href="index-5">Become a Delivery Agent/Driver</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!--end of container-->
    </footer>


</template>

<script>
    export default {
        name: 'SiteFooter',
        props: {
            showSubscribe: {
                type: Boolean,
                default: false
            }
        }
    }
</script>

<style>
    .iconi{
        font-size: 35px;
        padding-left: 5px;
        padding-right: 20px;
    }
    .soci-icons{
        text-align: center;
    }
</style>