<template>
    <section id="features" class="feature-section ptb-100" v-bind:class="{ 'gray-light-bg' : isGray }">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-md-9 col-lg-8">
                    <div class="section-heading text-center mb-5">
                        <h2>Relaxing at home, Having friends over or a Busy day at work?    </h2>
                        <h5 class="text-muted para-desc mb-0 mx-auto">Stay home, and let us deliver your favorite food to you. </h5>
                    </div>
                </div>
            </div>

            <div class="row align-items-center">
                <div class="col-lg-8 col-md-12">
                    <div class="row align-items-center">
                        <div class="col-md-6 col-12">
                            <div class="features-single-wrap mb-sm-0 mb-md-5 mb-lg-5">
                                <span class="ti-layout p-3 mr-4 mt-1 rounded-circle float-left"></span>
                                <div class="features-single-content d-block overflow-hidden">
                                    <h5 class="mb-2">Menu</h5>
                                    <p>Accessibility to all restaurants menu is easy now</p>
                                </div>
                            </div>
                        </div>

                        <div class="col-md-6 col-12">
                            <div class="features-single-wrap mb-sm-0 mb-md-5 mb-lg-5">
                                <span class="ti-themify-favicon-alt p-3 mr-4 mt-1 rounded-circle float-left"></span>
                                <div class="features-single-content d-block overflow-hidden">
                                    <h5 class="mb-2">Add to Cart</h5>
                                    <p>Fill the cart with your favorite dishes</p>
                                </div>
                            </div>
                        </div>

                        <div class="col-md-6 col-12">
                            <div class="features-single-wrap mb-sm-0 mb-md-5 mb-lg-5">
                                <span class="ti-eye p-3 mr-4 mt-1 rounded-circle float-left"></span>
                                <div class="features-single-content d-block overflow-hidden">
                                    <h5 class="mb-2">Track your Order</h5>
                                    <p>Track your order in real-time</p>
                                </div>
                            </div>
                        </div>

                        <div class="col-md-6 col-12">
                            <div class="features-single-wrap mb-sm-0 mb-md-5 mb-lg-5">
                                <span class="ti-thumb-up p-3 mr-4 mt-1 rounded-circle float-left"></span>
                                <div class="features-single-content d-block overflow-hidden">
                                    <h5 class="mb-2">Discount & Rewards</h5>
                                    <p>Being a regular customer, you will receive a Discount & Reward</p>
                                </div>
                            </div>
                        </div>

                        <div class="col-md-6 col-12">
                            <div class="features-single-wrap mb-sm-0 mb-md-5 mb-lg-5">
                                <span class="ti-mobile p-3 mr-4 mt-1 rounded-circle float-left"></span>
                                <div class="features-single-content d-block overflow-hidden">
                                    <h5 class="mb-2">Offers, Promo Coupons</h5>
                                    <p>Get amazing offers from your favorite restaurants</p>
                                </div>
                            </div>
                        </div>

                        <div class="col-md-6 col-12">
                            <div class="features-single-wrap mb-sm-0 mb-md-5 mb-lg-5">
                                <span class="ti-world p-3 mr-4 mt-1 rounded-circle float-left"></span>
                                <div class="features-single-content d-block overflow-hidden">
                                    <h5 class="mb-2">Cashless Experience </h5>
                                    <p>Don't use cash; pay with Hesab</p>
                                </div>
                            </div>
                        </div>

                        <div class="col-md-6 col-12">
                            <div class="features-single-wrap mb-sm-0 mb-md-5 mb-lg-5">
                                <span class="ti-world p-3 mr-4 mt-1 rounded-circle float-left"></span>
                                <div class="features-single-content d-block overflow-hidden">
                                    <h5 class="mb-2">Review & Rate in the App</h5>
                                    <p>Please review & rate your experience with BBR on the App itself</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-lg-4 col-md-4 col-12">
                    <img src="../../assets/img/bbr-img/407x613 Food.jpg" class="img-fluid mx-auto d-lg-block d-none" alt="app screen">
                </div>
            </div>
        </div>
    </section>
</template>

<script>
    export default {
        name: 'Features',
        props: {
            isGray: {
                type: Boolean,
                default: false
            }
        }
    }
</script>