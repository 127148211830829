<template>
    <section id="faq" class="ptb-100" v-bind:class="{ 'gray-light-bg' : isGray }">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-md-9 col-lg-8">
                    <div class="section-heading text-center mb-5">
                        <h2>Frequently Asked Questions</h2>
                        <!-- <p>Efficiently productivate reliable paradigms before ubiquitous models. Continually utilize frictionless expertise whereas tactical relationships. Still have questions? Contact us</p> -->
                    </div>
                </div>
            </div>
            <div class="row align-items-center">
                <div style="white-space:pre-wrap;"  class="col-lg-12 col-lg-12">
                    <accordian  :contents="contents" />
                </div>
            </div>
        </div>
    </section>
</template>

<script>
  import Accordian from '../../components/Accordian';
  export default {
      name: "Faq",
      props: {
          isGray: {
              type: Boolean,
              default: false
          },
      },
      components: {
          Accordian
      },
      data: function() {
          return {
              contents: [{
                title: 'How can I create an account in BBR?',
                description: 'Search BBR in Play Store/App Store, download the application, enter a valid Afghanistan phone number, enter the OTP code, set a password, and enjoy using the BBR app. For more information, call 580.',
                active: true
              },{
                title: 'Is BBR services available for all provinces?',
                description: 'So far, BBR services are available just in Kabul city, but soon BBR will be available in all provinces. For more information, call 580.',
                active: false
              },{
                title: 'How can I contact BBR call center?',
                description: 'Please dial 580 to contact BBR call center.',
                active: false
              }, {
                title: 'How much do you charge for getting a taxi?',
                description: 'In BBR Taxi, we charge based on k/m.',
                active: false
              }, {
                title: 'How can I become a driver/delivery agent?',
                description: 'Register \n- Open BBR web, go to join us, select Register Now, and follow below: \n- Fill the form, download the Wakil Guzar form, fill the Wakil Guzar form.\n- Attach the Guarantee letter from Wakil Guzar form, NID, vehicle documents, license, and photo. \nTrain \n- After reviewing your documents, the BBR team will call you for a training session.\nRide \n- When training is complete, you can start to earn money.',
                active: false
              }, {
                title: 'How can I send a parcel?',
                description: 'Open the BBR app, select Parcel Delivery and follow below: \n\nFill in Pick-up details, choose NEXT, enter Delivery Details, choose NEXT, and choose DELIVER PACKAGE. For more information call 580.',
                active: false
              }, {
                title: 'Are you register with all restaurants inside Kabul city?',
                description: 'Currently, we are registered with more than 60 well-known restaurants',
                active: false
              }, {
                title: 'How does Car Rent works?',
                description: 'In-Car Rent, we have three vehicle categories, Business, Economy, and Comfort, which have different charges. For more information contact 580',
                active: false
              }]
          };
      }

  };
</script>
