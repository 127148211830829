<template>
    <div>
        <nav-bar />

        <div class="main">
            <page-header
                    title="Blog Details Right Sidebar"
                    subtitle="Rapidiously deploy world-class platforms whereas collaborative interfaces. Phosfluorescently facilitate corporate innovation via excellent web technically sound."
            />
            <breadcrumb :crumbs="crumbs" />

            <div class="module ptb-100">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-8 col-md-8">
                            <!-- Post-->
                            <article class="post">
                                <div class="post-preview"><img src="../../assets/img/blog/1-w.jpg" alt="article" class="img-fluid" /></div>
                                <div class="post-wrapper">
                                    <div class="post-header">
                                        <h1 class="post-title">Minimalist Chandelier</h1>
                                        <ul class="post-meta">
                                            <li>November 18, 2016</li>
                                            <li>In <a href="#">Branding</a>, <a href="#">Design</a></li>
                                            <li><a href="#">3 Comments</a></li>
                                        </ul>
                                    </div>
                                    <div class="post-content">
                                        <p>Meh synth Schlitz, tempor duis single-origin coffee ea next level ethnic fingerstache fanny pack nostrud. Seitan High Life reprehenderit consectetur cupidatat kogi about me. Photo booth anim 8-bit hella, PBR 3 wolf moon beard Helvetica. Salvia esse nihil, flexitarian Truffaut synth art party deep v chillwave. Seitan High Life reprehenderit consectetur cupidatat kogi.</p>
                                        <p>Exercitation photo booth stumptown tote bag Banksy, elit small batch freegan sed. Craft beer elit seitan exercitation, photo booth et 8-bit kale chips proident chillwave deep v laborum. Aliquip veniam delectus, Marfa eiusmod Pinterest in do umami readymade swag. Selfies iPhone Kickstarter, drinking vinegar jean shorts fixie consequat flexitarian four loko.</p>
                                        <blockquote class="blockquote">
                                            <p>To be yourself in a world that is constantly trying to make you something else is the greatest accomplishment.</p>
                                            <footer class="blockquote-footer">Amanda Pollock, Google Inc.</footer>
                                        </blockquote>
                                        <p>Meh synth Schlitz, tempor duis single-origin coffee ea next level ethnic fingerstache fanny pack nostrud. Seitan High Life reprehenderit consectetur cupidatat kogi about me. Photo booth anim 8-bit hella, PBR 3 wolf moon beard Helvetica.</p>
                                        <p>Meh synth Schlitz, tempor duis single-origin coffee ea next level ethnic fingerstache fanny pack nostrud. Seitan High Life reprehenderit consectetur cupidatat kogi about me. Photo booth anim 8-bit hella, PBR 3 wolf moon beard Helvetica. Salvia esse nihil, flexitarian Truffaut synth art party deep v chillwave. Seitan High Life reprehenderit consectetur cupidatat kogi.</p>
                                        <ol>
                                            <li>Digital Strategy</li>
                                            <li>Software Development</li>
                                            <li>Interaction Design</li>
                                        </ol>
                                        <p>Meh synth Schlitz, tempor duis single-origin coffee ea next level ethnic fingerstache fanny pack nostrud. Seitan High Life reprehenderit consectetur cupidatat kogi about me. Photo booth anim 8-bit hella, PBR 3 wolf moon beard Helvetica.</p>
                                        <p>Exercitation photo booth stumptown tote bag Banksy, elit small batch freegan sed. Craft beer elit seitan exercitation, photo booth et 8-bit kale chips proident chillwave deep v laborum. Aliquip veniam delectus, Marfa eiusmod Pinterest in do umami readymade swag. Selfies iPhone Kickstarter, drinking vinegar jean shorts fixie consequat flexitarian four loko.</p>
                                    </div>
                                    <div class="post-footer">
                                        <div class="post-tags"><a href="#">Lifestyle</a><a href="#">Music</a><a href="#">News</a><a href="#">Travel</a></div>
                                    </div>
                                </div>
                            </article>
                            <!-- Post end-->

                            <!-- Comments area-->
                            <div class="comments-area mb-5">
                                <h5 class="comments-title">3 Comments</h5>
                                <div class="comment-list">
                                    <!-- Comment-->
                                    <div class="comment">
                                        <div class="comment-author"><img class="avatar img-fluid rounded-circle" src="../../assets/img/client/2.jpg" alt="comment" /></div>
                                        <div class="comment-body">
                                            <div class="comment-meta">
                                                <div class="comment-meta-author"><a href="#">Jason Ford</a></div>
                                                <div class="comment-meta-date"><a href="#">May 5, 2015 at 4:51 am</a></div>
                                            </div>
                                            <div class="comment-content">
                                                <p>Meh synth Schlitz, tempor duis single-origin coffee ea next level ethnic fingerstache fanny pack nostrud. Seitan High Life reprehenderit consectetur cupidatat kogi about me. Photo booth anim 8-bit hella, PBR 3 wolf moon beard Helvetica.</p>
                                            </div>
                                            <div class="comment-reply"><a href="#">Reply</a></div>
                                        </div>
                                        <!-- Subcomment-->
                                        <div class="children">
                                            <div class="comment">
                                                <div class="comment-author"><img class="avatar img-fluid rounded-circle" src="../../assets/img/client/3.jpg" alt="comment" /></div>
                                                <div class="comment-body">
                                                    <div class="comment-meta">
                                                        <div class="comment-meta-author"><a href="#">Harry Benson</a></div>
                                                        <div class="comment-meta-date"><a href="#">May 5, 2015 at 4:51 am</a></div>
                                                    </div>
                                                    <div class="comment-content">
                                                        <p>Meh synth Schlitz, tempor duis single-origin coffee ea next level ethnic fingerstache fanny pack nostrud. Seitan High Life reprehenderit consectetur cupidatat kogi about me. Photo booth anim 8-bit hella.</p>
                                                    </div>
                                                    <div class="comment-reply"><a href="#">Reply</a></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- Comment-->
                                    <div class="comment">
                                        <div class="comment-author"><img class="avatar img-fluid rounded-circle" src="../../assets/img/client/4.jpg" alt="comment" /></div>
                                        <div class="comment-body">
                                            <div class="comment-meta">
                                                <div class="comment-meta-author"><a href="#">Henry Cain</a></div>
                                                <div class="comment-meta-date"><a href="#">May 5, 2015 at 4:51 am</a></div>
                                            </div>
                                            <div class="comment-content">
                                                <p>Meh synth Schlitz, tempor duis single-origin coffee ea next level ethnic fingerstache fanny pack nostrud. Seitan High Life reprehenderit consectetur cupidatat kogi about me. Photo booth anim 8-bit hella, PBR 3 wolf moon beard Helvetica.</p>
                                            </div>
                                            <div class="comment-reply"><a href="#">Reply</a></div>
                                        </div>
                                    </div>
                                </div>
                                <div class="comment-respond">
                                    <h5 class="comment-reply-title">Leave a Reply</h5>
                                    <p class="comment-notes">Your email address will not be published. Required fields are marked</p>
                                    <form class="comment-form row">
                                        <div class="form-group col-md-4">
                                            <input class="form-control" type="text" placeholder="Name">
                                        </div>
                                        <div class="form-group col-md-4">
                                            <input class="form-control" type="text" placeholder="Email">
                                        </div>
                                        <div class="form-group col-md-4">
                                            <input class="form-control" type="url" placeholder="Website">
                                        </div>
                                        <div class="form-group col-md-12">
                                            <textarea class="form-control" rows="8" placeholder="Comment"></textarea>
                                        </div>
                                        <div class="form-submit col-md-12">
                                            <button class="btn btn-brand-02" type="submit">Post Comment</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                            <!-- Comments area end-->
                        </div>
                        <div class="col-lg-4 col-md-4">
                            <div class="sidebar-right pl-4">

                                <!-- Search widget-->
                                <aside class="widget widget-search">
                                    <form>
                                        <input class="form-control" type="search" placeholder="Type Search Words">
                                        <button class="search-button" type="submit"><span class="ti-search"></span></button>
                                    </form>
                                </aside>


                                <!-- Categories widget-->
                                <aside class="widget widget-categories">
                                    <div class="widget-title">
                                        <h6>Categories</h6>
                                    </div>
                                    <ul>
                                        <li><a href="#">Journey <span class="float-right">112</span></a></li>
                                        <li><a href="#">Development <span class="float-right">86</span></a></li>
                                        <li><a href="#">Sport <span class="float-right">10</span></a></li>
                                        <li><a href="#">Photography <span class="float-right">144</span></a></li>
                                        <li><a href="#">Symphony <span class="float-right">18</span></a></li>
                                    </ul>
                                </aside>

                                <!-- Recent entries widget-->
                                <aside class="widget widget-recent-entries-custom">
                                    <div class="widget-title">
                                        <h6>Recent Posts</h6>
                                    </div>
                                    <ul>
                                        <li class="clearfix">
                                            <div class="wi"><a href="#"><img src="../../assets/img/blog/1.jpg" alt="recent post" class="img-fluid rounded" /></a></div>
                                            <div class="wb"><a href="#">Map where your photos were taken and discover local points.</a><span class="post-date">May 8, 2016</span></div>
                                        </li>
                                        <li class="clearfix">
                                            <div class="wi"><a href="#"><img src="../../assets/img/blog/2.jpg" alt="recent post" class="img-fluid rounded" /></a></div>
                                            <div class="wb"><a href="#">Map where your photos were taken and discover local points.</a><span class="post-date">May 8, 2016</span></div>
                                        </li>
                                        <li class="clearfix">
                                            <div class="wi"><a href="#"><img src="../../assets/img/blog/3.jpg" alt="recent post" class="img-fluid rounded" /></a></div>
                                            <div class="wb"><a href="#">Map where your photos were taken and discover local points.</a><span class="post-date">May 8, 2016</span></div>
                                        </li>
                                    </ul>
                                </aside>

                                <!-- Subscribe widget-->
                                <aside class="widget widget-categories">
                                    <div class="widget-title">
                                        <h6>Newsletter</h6>
                                    </div>
                                    <p>Enter your email address below to subscribe to my newsletter</p>
                                    <form action="#" method="post" class="d-none d-md-block d-lg-block">
                                        <input type="text" class="form-control input" id="email-footer" name="email" placeholder="info@yourdomain.com">
                                        <button type="submit" class="btn btn-brand-02 btn-block btn-not-rounded mt-3">Subscribe</button>
                                    </form>
                                </aside>

                                <!-- Tags widget-->
                                <aside class="widget widget-tag-cloud">
                                    <div class="widget-title">
                                        <h6>Tags</h6>
                                    </div>
                                    <div class="tag-cloud"><a href="#">e-commerce</a><a href="#">portfolio</a><a href="#">responsive</a><a href="#">bootstrap</a><a href="#">business</a><a href="#">corporate</a></div>
                                </aside>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <site-footer />
        <copyright />
    </div>
</template>
<script>
    import NavBar from "../../views/commons/NavBar";
    import PageHeader from "../../views/commons/PageHeader";
    import SiteFooter from "../../views/commons/SiteFooter";
    import Copyright from "../../views/commons/Copyright";
    import Breadcrumb from "../../views/commons/Breadcrumb";


    export default {
        components: { Breadcrumb, SiteFooter, PageHeader, NavBar, Copyright },
        data: function(){
            return {
                crumbs: [
                    { 'link': '#', 'name': 'Home', 'isActive': false },
                    { 'link': '#', 'name': 'Pages', 'isActive': false },
                    { 'link': '#', 'name': 'Blog Details right sidebar', 'isActive': true }
                ]
            }
        }
    }
</script>