<template>
  <!--hero section start-->
  <section class="position-relative overflow-hidden hero-section-3 ptb-100">
    <!--animated circle shape start-->
    <div class="animated-shape-wrap">
      <div class="animated-shape-item"></div>
      <div class="animated-shape-item"></div>
      <div class="animated-shape-item"></div>
      <div class="animated-shape-item"></div>
      <div class="animated-shape-item"></div>
    </div>
    <!--animated circle shape end-->
    <img src="../../assets/img/combined-shape.svg" alt="s" class="shape-img-2">
    <img src="../../assets/img/shape-1.svg" alt="group shape" width="100" class="img-fluid group-shape-1">
    <img src="../../assets/img/shape-2.svg" alt="group shape" width="60" class="img-fluid group-shape-2">
    <div class="container">
      <div class="row align-items-center justify-content-between">
        <div class="col-md-6 col-lg-6">
          <div class="hero-content-left pt-5">
            <h1 class="text-white3 text-white4">Become a Merchant</h1>
            <p class="lead lead2">We’ll handle delivery for your business. Delivery through us!</p>
            <div class="action-btns mt-4">
              <ul class="list-inline">
                <li class="list-inline-item">
                  <a href="https://docs.google.com/forms/d/e/1FAIpQLScThi5MhWTWCEAe91TzuYVQkngFf-wDUy3CgydatUeJTxI5Vg/viewform?usp=sf_link" target="_blank" class="d-flex align-items-center app-download-btn btn btn-outline-brand-02 btn-rounded">
                    <span class="icon-size-sm mr-3">
                      <i class="fas fa-file-signature"></i>
                    </span>
                    <div class="download-text text-left">
                      <p style="margin:auto">Register Now</p>
                    </div>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="col-md-6 col-lg-6">
          <div class="img-wrap">
            <img src="../../assets/img/hero-image.svg" alt="hero" class="img-fluid">
          </div>
        </div>
      </div>
    </div>

    <!--shape image start-->
    <img src="../../assets/img/hero-bg-shape-4.svg" class="shape-image" alt="shape image">
    <!--shape image end-->
  </section>
</template>

<script>
export default {
  name: "Banner",
};
</script>

<!--<style lang="scss">-->
  <!--.hero-bottom-shape-bg {-->
    <!--background: url('../../assets/img/hero-bottom-shape-2.svg')no-repeat bottom center-->
  <!--}-->
<!--</style>-->