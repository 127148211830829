<template>
    <header class="header">
        <nav class="navbar navbar-expand-lg fixed-top" v-bind:class="{ affix: hasAffix, 'custom-nav': coloredLogo}">
            <div class="container">
                <a class="navbar-brand" href="/">
                    <img
                        src="../../assets/img/bbr-img/logo-1.png" width="4em" style="width: 4em;margin-top: 6px;margin-bottom: 6px;"
                    />
                </a>
                <button class="navbar-toggler" type="button" @click="mobileNavClicked" v-bind:class="{ 'collapsed': collapsed }">
                    <span class="ti-menu"></span>
                </button>
                <div class="collapse navbar-collapse h-auto" v-bind:class="{ 'show': !collapsed }" id="navbarSupportedContent">
                    <ul class="navbar-nav ml-auto menu">
                        <li>
                            <router-link to="index-7">About</router-link>
                        </li>
                        <li>
                            <router-link to="index-3">Taxi</router-link>
                        </li>
                        <li>
                            <router-link to="index-10">Food</router-link>
                        </li>
                        <li>
                            <router-link to="index-2">Parcel</router-link>
                        </li>
                        <li>
                            <router-link to="index-8">Rent a Car</router-link>
                        </li>
                        <li>
                            <router-link to="index-4">Shopping</router-link>
                        </li>
                        <li>
                            <a href="#" class="dropdown-toggle">Join us</a>
                                    <ul class="sub-menu">
                                        <li>
                                            <router-link to="index-6">Become a Merchant</router-link>
                                        </li>
                                        <li>
                                            <router-link to="index-5">Become a Delivery Agent/Driver</router-link>
                                        </li>
                                    </ul>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
    </header>
</template>

<script>
    export default {
        props: {
            coloredLogo: {
                type: Boolean,
                default: false
            }
        },
        data: function() {
            return {
                windowTop: 0,
                collapsed: true
            }
        },
        mounted() {
            window.addEventListener("scroll", this.onScroll)
        },
        beforeDestroy() {
            window.removeEventListener("scroll", this.onScroll)
        },
        methods: {
            onScroll: function() {
                this.windowTop = window.top.scrollY
            },
            mobileNavClicked: function() {
                this.collapsed = !this.collapsed;
            }
        },
        computed: {
            hasAffix: function () {
                return this.windowTop > 0;
            }
        }
    }
</script>

