<template>
    <section class="promo-block ptb-100" v-bind:class="{ 'gray-light-bg' : isGray }">
        <div class="container">
            <div class="row align-items-center justify-content-between">
                <div class="col-md-12 col-lg-12">
                    <div class="promo-content-wrap">
                        <h2>Our Vision</h2>
                        <p>BBR envisions that every individual Afghan in remote and non-remote areas shop with internet through BBR platforms.</p>
                        <ul class=" pt-3">
                            <li><strong>BBR Mission</strong> – We endeavor to provide the platforms of E-Commerce in the context of Online Shopping, Food Delivery, Parcels Delivery and Taxi, with easy technologies, simple payment systems, reasonable prices, to time delivery and quality-based products where every individual select the product of their choice with belief and trust.</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
    export default {
        name: 'About',
        props: {
            isGray: {
                type: Boolean,
                default: false
            }
        }
    }
</script>