<template>
    <section id="features" class="feature-section ptb-100" v-bind:class="{ 'gray-light-bg' : isGray }">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-md-9 col-lg-8">
                    <div class="section-heading text-center mb-5">
                        <h2>Why become a partner Agent? </h2>
                        <h5 class="text-muted para-desc mb-0 mx-auto">Partner with us to drive your own livelihood and more. Let's get started on this journey together.</h5>
                    </div>
                </div>
            </div>

            <div class="row align-items-center">
                <div class="col-lg-8 col-md-12">
                    <div class="row align-items-center">
                        <div class="col-md-6 col-12">
                            <div class="features-single-wrap mb-sm-0 mb-md-5 mb-lg-5">
                                <span class="ti-layout p-3 mr-4 mt-1 rounded-circle float-left"></span>
                                <div class="features-single-content d-block overflow-hidden">
                                    <h5 class="mb-2">Be Your Own Boss.</h5>
                                    <p>Work on your own terms, based on your own schedule.</p>
                                </div>
                            </div>
                        </div>

                        <div class="col-md-6 col-12">
                            <div class="features-single-wrap mb-sm-0 mb-md-5 mb-lg-5">
                                <span class="ti-eye p-3 mr-4 mt-1 rounded-circle float-left"></span>
                                <div class="features-single-content d-block overflow-hidden">
                                    <h5 class="mb-2">Make Your Own Money. </h5>
                                    <p>With BBR you can earn as much money as you want based on your daily performance.</p>
                                </div>
                            </div>
                        </div>

  
                        <div class="col-md-6 col-12">
                            <div class="features-single-wrap mb-sm-0 mb-md-5 mb-lg-5">
                                <span class="ti-mobile p-3 mr-4 mt-1 rounded-circle float-left"></span>
                                <div class="features-single-content d-block overflow-hidden">
                                    <h5 class="mb-2">Serving You Always.</h5>
                                    <p>Partners/drivers are a priority for the BBR company.</p>
                                </div>
                            </div>
                        </div>

                        <div class="col-md-6 col-12">
                            <div class="features-single-wrap mb-sm-0 mb-md-5 mb-lg-5">
                                <span class="ti-world p-3 mr-4 mt-1 rounded-circle float-left"></span>
                                <div class="features-single-content d-block overflow-hidden">
                                    <h5 class="mb-2">24/7 customer support.</h5>
                                    <p>Get quick support whenever you need it.</p>
                                </div>
                            </div>
                        </div>

                        <div class="col-md-6 col-12">
                            <div class="features-single-wrap mb-sm-0 mb-md-5 mb-lg-5">
                                <span class="ti-world p-3 mr-4 mt-1 rounded-circle float-left"></span>
                                <div class="features-single-content d-block overflow-hidden">
                                    <h5 class="mb-2">In-app driver help center.</h5>
                                    <p>Through the BBR help center within the App, partners/drivers can solve their issues.</p>
                                </div>
                            </div>
                        </div>

                        <div class="col-md-6 col-12">
                            <div class="features-single-wrap mb-sm-0 mb-md-5 mb-lg-5">
                                <span class="ti-world p-3 mr-4 mt-1 rounded-circle float-left"></span>
                                <div class="features-single-content d-block overflow-hidden">
                                    <h5 class="mb-2">Online/offline Training and Tutorials.</h5>
                                    <p>BBR team provide its partners/drivers online and offline trainings.</p>
                                </div>
                            </div>
                        </div>

                        <div class="col-md-6 col-12">
                            <div class="features-single-wrap mb-sm-0 mb-md-5 mb-lg-5">
                                <span class="ti-themify-favicon-alt p-3 mr-4 mt-1 rounded-circle float-left"></span>
                                <div class="features-single-content d-block overflow-hidden">
                                    <h5 class="mb-2">Full time? Part-time? With BBR, you work on your own time.</h5>
                                    <p>Work with BBR whenever you want.</p>
                                </div>
                            </div>
                        </div>

                        <div class="col-md-6 col-12">
                            <div class="features-single-wrap mb-sm-0 mb-md-5 mb-lg-5">
                                <span class="ti-thumb-up p-3 mr-4 mt-1 rounded-circle float-left"></span>
                                <div class="features-single-content d-block overflow-hidden">
                                    <h5 class="mb-2">You decide how much money you make.</h5>
                                    <p>BBR partner/drivers work on their own terms.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-lg-4 col-md-4 col-12">
                    <img src="../../assets/img/app-mobile-image.png" class="img-fluid mx-auto d-lg-block d-none" alt="app screen">
                </div>
            </div>
        </div>
    </section>
</template>

<script>
    export default {
        name: 'Features',
        props: {
            isGray: {
                type: Boolean,
                default: false
            }
        }
    }
</script>