<template>
    <section id="about" class="about-us ptb-100 position-relative">
        <div class="container">
            <div class="row align-items-center justify-content-lg-between justify-content-md-center">
                <div class="col-md-5 col-lg-4">
                    <div class="about-content-right">
                        <img src="../../assets/img/bbr-img/350x625 Rental Car.jpg" alt="about us" class="img-fluid">
                    </div>
                </div>
                <div class="col-md-12 col-lg-7">
                    <div class="about-content-left section-heading">
                        <h2>Why Car Rent?</h2>

                        <ul class="check-list-wrap pt-3">
                            <li><strong>Easy Bookings</strong> – Place your order within seconds with BBR.</li>
                            <li><strong>Advance Booking</strong> – Book your favorite Car with drivers.</li>
                            
                            <li><strong>Corporate Car Rental Services</strong> – Get wherever you need as quickly as possible.</li>

                            
                            <li><strong>Secure and Safe Rides</strong> – BBR always makes sure to have a secure and convenient ride</li>
                            <li><strong>Vehicle Options</strong> – BBR offers a variety of vehicle options (economy, comfort, and business)</li>
                            <!-- <li><strong>Big Data Analytics</strong> – Quickly incubate revolutionary data and effective infomediaries. Credibly transform high standards in convergence via market positioning interfaces.</li> -->
                        </ul>
                        <!-- <div class="action-btns mt-4">
                            <a href="#" class="btn btn-brand-02 mr-3">Get Start Now</a>
                            <a href="#" class="btn btn-outline-brand-02">Learn More</a>
                        </div> -->
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
    export default {
        name: 'About',
        props: {
            isGray: {
                type: Boolean,
                default: false
            }
        }
    }
</script>