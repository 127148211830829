import Vue from 'vue';
import App from './App.vue';
import './registerServiceWorker';
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import router from './router';
import store from './store';

/* asset imports */

import './assets/scss/main.scss';
/* plugins */
import VueScrollTo from 'vue-scrollto';
import VueYoutube from 'vue-youtube';
import BackToTop from 'vue-backtotop';
import { gsap } from 'gsap';
import { CSSRulePlugin } from "gsap/CSSRulePlugin";
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue';


Vue.use(VueScrollTo);
Vue.use(VueYoutube);
Vue.use(BackToTop);

// Install BootstrapVue
Vue.use(BootstrapVue)
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin)

gsap.registerPlugin(CSSRulePlugin);

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app');

