<template>
  <section class="position-relative gradient-bg ptb-100">
    <div class="container">
      <div class="row align-items-center">
        <!-- <div class="col-md-6 col-lg-5 mb-4 mb-sm-4 mb-md-0 mb-lg-0">
          <div class="testimonial-heading">
            <h2 class=">Customer Testimonial</h2>
            <p>
              Collaboratively actualize excellent schemas without effective
              models. Synergistically engineer functionalized applications
              rather than backend e-commerce.
            </p>
          </div>
        </div> -->
        <div class="col-md-12 col-lg-12">
          <div class="testimonial-content-wrap">
            <!-- <div
              class="owl-carousel owl-theme client-testimonial-1 dot-indicator testimonial-shape"
            > -->
            <carousel
              :autoplay="true"
              :dots="true"
              class="owl-theme client-testimonial-1 dot-indicator testimonial-shape owl-loaded owl-drag"
              :loop="true"
              :responsiveClass="true"
              :margin="30"
              :nav="false"
              :autoplayHoverPause="true"
              :lazyLoad="true"
              :items="1"
            >
              <div class="item">
                <div class="testimonial-quote-wrap">
                  <div class="media author-info mb-3">
                    <div class="author-img mr-3">
                      <img
                        src="../../assets/img/client/1.jpg"
                        alt="client"
                        class="img-fluid"
                      />
                    </div>
                    <div class="media-body">
                      <h5 class="mb-0">Mr. Kamal Khan</h5>
                      <span>Head Of Admin</span>
                    </div>
                  </div>
                  <div class="client-say">
                    <p>
                      Thank you so much BBR. It has really helped me and my family. I daily use BBR, especially Taxi. I
                      believe the services which BBR provides will help every individual Afghan, because it’s an app
                      for all Afghanistan. 
                      <br><br>
                      Thank you once again BBR
                    </p>
                  </div>
                </div>
              </div>
              <div class="item">
                <div class="testimonial-quote-wrap">
                  <div class="media author-info mb-3">
                    <div class="author-img mr-3">
                      <img
                        src="../../assets/img/client/2.jpg"
                        alt="client"
                        class="img-fluid"
                      />
                    </div>
                    <div class="media-body">
                      <h5 class="mb-0">Mr. Ahmad Rasooli</h5>
                      <span>HR Manager</span>
                    </div>
                  </div>
                  <div class="client-say">
                    <p>
                      Wow, I just say Wow to such awesome services. I have not seen another platfrom the same as
                      BBR in Afghanistan. BBR has helped me out very much. Through BBR I get my daily rides and
                      parcel whatever my mother orders. In addition, I sometimes order food for my whole family as
                      well.
                      <br><br>
                      Thank you so much BBR from bottom of my heart.
                    </p>
                  </div>
                </div>
              </div>
            </carousel>
            <!-- </div> -->
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import carousel from "vue-owl-carousel";
export default {
  name: "Testimonial",
  components: { carousel },
};
</script>

<style></style>
