<template>
  <div id="features" class="feature-section  ptb-100" v-bind:class="{ 'gray-light-bg': isGray }">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-md-9 col-lg-9">
          <div class="section-heading text-center mb-5">
            <h2>BBR Rewards</h2>
            <!-- <p>Objectively deliver professional value with diverse web-readiness. Collaboratively transition wireless customer service without goal-oriented catalysts for change. Collaboratively.</p> -->
          </div>
        </div>
      </div>

      <!--feature new style start-->
      <!-- <div class="row align-items-center justify-content-md-center">
        <div class="col-lg-4 col-md-12">
          <div class="row">
            <div class="col-12">
              <div class="d-flex align-items-start mb-sm-0 mb-md-3 mb-lg-3">
                <span class="icon-size-md color-secondary mr-4"><i class="fas fa-taxi"></i></span>
                <div class="icon-text">
                  <h5 class="mb-2">BBR Refer and Earn</h5>
                  <p>Share the App with your friends and families, enter the referral code and earn money</p>
                </div>
              </div>
            </div>
            <div class="col-12">
              <div class="d-flex align-items-start mb-sm-0 mb-md-3 mb-lg-3">
                <span class="icon-size-md color-secondary mr-4"><i class="fas fa-truck-loading"></i></span>
                <div class="icon-text">
                  <h5 class="mb-2">BBR Gift Programs</h5>
                  <p>Answer our questions in different event/programs on our social media and get the BBR gift package. </p>
                </div>
              </div>
            </div>
            <div class="col-12">
              <div class="d-flex align-items-start mb-sm-0 mb-md-3 mb-lg-3">
                <span class="icon-size-md color-secondary mr-4"><i class="fas fa-search-location"></i></span>
                <div class="icon-text">
                  <h5 class="mb-2">BBR Gift Cards</h5>
                  <p>
                      BBR Gift Card has the following benefits:
                      Get 20% discount from BBR Shopping, 20% discount on BBR Food, get two free rides of BBR Taxi
                      in Kabul city (for a limit of AFN 250), and get two free Parcel Delivery as well. 
                      
                      </p>
                </div>
              </div>
            </div>


          <div class="col-12">
              <div class="d-flex align-items-start mb-sm-0 mb-md-3 mb-lg-3">
                <span class="icon-size-md color-secondary mr-4"><i class="fas fa-search-location"></i></span>
                <div class="icon-text">
                  <h5 class="mb-2">NOTE: BBR Gift Card is valid just for one month.</h5>
                  <p>
                      How to get BBR Gift Card?
                      <ul>
                        <li>Ten orders from BBR Food</li>
                        <li>Ten orders from BBR Parcel</li>
                        <li>Ten rides from BBR Taxi</li>
                      </ul>
                    </p>

                    NOTE: You must complete the above requirements within a month to get BBR Gift Card. 
                </div>
              </div>
            </div>

            <div class="col-12">
              <div class="d-flex align-items-start mb-sm-0 mb-md-3 mb-lg-3">
                <span class="icon-size-md color-secondary mr-4"><i class="fas fa-search-location"></i></span>
                <div class="icon-text">
                  <h5 class="mb-2">BBR Banquet</h5>
                  <p>
                      Become our Loyal Customer (who has the most usage of the BBR app within a month). BBR will
                      surprise you and your family with a banquet.
                  </p>
                </div>
              </div>
            </div>



          </div>
        </div>
        <div class="col-lg-4 col-md-5 d-none d-sm-none d-md-block d-lg-block">
          <div class="position-relative pb-md-5 py-lg-0">
            <img
              alt="Image placeholder"
              src="../../assets/img/app-mobile-image.png"
              class="img-center img-fluid"
            />
          </div>
        </div>
        <div class="col-lg-4 col-md-12">
          <div class="row">
            <div class="col-12">
              <div class="d-flex align-items-start mb-sm-0 mb-md-3 mb-lg-3">
                <span class="icon-size-md color-secondary mr-4"><i class="fas fa-motorcycle"></i></span>
                <div class="icon-text">
                  <h5 class="mb-2">Mr. Kamal Khan</h5>
                  <p>
                      Thank you so much BBR. It has really helped me and my family. I daily use BBR, especially Taxi. I
                      believe the services which BBR provides will help every individual Afghan, because it’s an app
                      for all Afghanistan. 

                      Thank you once again BBR 
                      </p>
                </div>
              </div>
            </div>
            <div class="col-12">
              <div class="d-flex align-items-start mb-sm-0 mb-md-3 mb-lg-3">
                <span class="icon-size-md color-secondary mr-4"><i class="fas fa-car-side"></i></span>
                <div class="icon-text">
                  <h5 class="mb-2">Mr. Ahmad Rasooli</h5>
                  <p>
                      Wow, I just say Wow to such awesome services. I have not seen another platfrom the same as
                      BBR in Afghanistan. BBR has helped me out very much. Through BBR I get my daily rides and
                      parcel whatever my mother orders. In addition, I sometimes order food for my whole family as
                      well.

                      Thank you so much BBR from bottom of my heart. 
                    </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> -->
      <!--feature new style end-->
      
      <div class="container">
        <div class="row">
          <div class="col-lg-4">
            <div class="outer-st">
              <div class="re-out">
                <img src="../../assets/img/earnings.png" alt="" width="" class="re-img">
              </div>
              <p class="title-st">
                BBR Refer and Earn
              </p>
              <p class="desc-st">
                      Share the App with your friends and families, enter the referral code and earn money.
              </p>
            </div>
          </div>
          <div class="col-lg-4">
            <div class="outer-st">
              <div class="re-out">
                <img src="../../assets/img/gift.png" alt="" width="" class="re-img">
              </div>
              <p class="title-st">
                BBR Gift Programs
              </p>
              <p class="desc-st">
                      Answer our questions in different event/programs on our social media and get the BBR gift
                      package.  
              </p>
            </div>
          </div>
          <div class="col-lg-4">
            <div class="outer-st">
              <div class="re-out">
                <img src="../../assets/img/like.png" alt="" width="" class="re-img">
              </div>
              <p class="title-st">
                BBR Banquet
              </p>
              <p class="desc-st">
                      Become our Loyal Customer (who has the most usage of the BBR app within a month). BBR will
                      surprise you and your family with a banquet. 
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>



<script>
export default {
  name: "Features",
  props: {
    isGray: {
      type: Boolean,
      default: false
    },
  },
};
</script>

<style>
</style>