
    <template>
    <section class="promo-section ptb-100" v-bind:class="{ 'gray-light-bg' : isGray }">
        <div class="container container2">
            <div class="row justify-content-center">
                <div class="col-md-10 col-lg-8">
                    <div class="section-heading text-center">
                        <!-- <h2>Your need our responsibility just BBR with one app</h2> -->
                        <!-- <p>Uniquely repurpose strategic core competencies with progressive content. Assertively transition ethical imperatives and collaborative manufactured products. </p> -->
                    </div>
                </div>
            </div>
            <div class="row justify-content-md-center justify-content-sm-center">
                <div class="col-md-6 col-lg-2">
                    <!-- <div class="card border-0 single-promo-card single-promo-hover text-center p-2 mt-4">
                        <div class="card-body"> -->
                            <!-- <div class="pb-2"> -->
                                <span class="servImg">
                                  <img src="../../assets/img/bbr-img/Group 1.png" class="servImg" alt="">
                                </span>
                            <!-- </div> -->
                            <!-- <div class="pt-2 pb-3">
                                <p class="bbrInitial">تكسى</p>
                            </div> -->
                        <!-- </div>
                    </div> -->
                </div>
                <div class="col-md-6 col-lg-2">
                    <!-- <div class="card border-0 single-promo-card single-promo-hover text-center p-2 mt-4">
                        <div class="card-body"> -->
                            <!-- <div class="pb-2"> -->
                                <span class="servImg">
                                  <img src="../../assets/img/bbr-img/Group 2.png" class="servImg" alt="">
                                </span>
                            <!-- </div> -->
                            <!-- <div class="pt-2 pb-3">
                                <p class="bbrInitial">تكسى</p>
                            </div> -->
                        <!-- </div>
                    </div> -->
                </div>
                <div class="col-md-6 col-lg-2">
                    <!-- <div class="card border-0 single-promo-card single-promo-hover text-center p-2 mt-4">
                        <div class="card-body"> -->
                            <!-- <div class="pb-2"> -->
                                <span class="servImg">
                                  <img src="../../assets/img/bbr-img/Group 3.png" class="servImg" alt="">
                                </span>
                            <!-- </div> -->
                            <!-- <div class="pt-2 pb-3">
                                <p class="bbrInitial">تكسى</p>
                            </div> -->
                        <!-- </div>
                    </div> -->
                </div>
                <div class="col-md-6 col-lg-2">
                    <!-- <div class="card border-0 single-promo-card single-promo-hover text-center p-2 mt-4">
                        <div class="card-body"> -->
                            <!-- <div class="pb-2"> -->
                                <span class="servImg">
                                  <img src="../../assets/img/bbr-img/Group 4.png" class="servImg" alt="">
                                </span>
                            <!-- </div> -->
                            <!-- <div class="pt-2 pb-3">
                                <p class="bbrInitial">تكسى</p>
                            </div> -->
                        <!-- </div>
                    </div> -->
                </div>
                <div class="col-md-6 col-lg-2">
                    <!-- <div class="card border-0 single-promo-card single-promo-hover text-center p-2 mt-4">
                        <div class="card-body"> -->
                            <!-- <div class="pb-2"> -->
                                <span class="servImg">
                                  <img src="../../assets/img/bbr-img/Group 5.png" class="servImg" alt="">
                                </span>
                            <!-- </div> -->
                            <!-- <div class="pt-2 pb-3">
                                <p class="bbrInitial">تكسى</p>
                            </div> -->
                        <!-- </div>
                    </div> -->
                </div>
            </div>
        </div>
    </section>
</template>



<script>
export default {
  name: "Promo",
  props: {
    showDesc: {
      type: Boolean,
      default: false
    },
    isGray: {
      type: Boolean,
      default: false
    },
  }
};
</script>

<style>

  .home-img-title{
      width: 5.5em;
  }

  .pb-2{
      text-align: center;
  }

  @media (min-width: 1200px){
    .container2, .container2-sm, .container2-md, .container2-lg, .container2-xl {
        max-width: 1677px !important;
    }
}

</style>