<template>
    <section id="about" class="about-us ptb-100 position-relative">
        <div class="container">
            <div class="row align-items-center justify-content-lg-between justify-content-md-center">
                <div class="col-md-5 col-lg-4">
                    <div class="about-content-right">
                        <img src="../../assets/img/app-mobile-image-2.png" alt="about us" class="img-fluid">
                    </div>
                </div>
                <div class="col-md-12 col-lg-7">
                    <div class="about-content-left section-heading">
                        <h2>Why Shopping?</h2>

                        <ul class="check-list-wrap pt-3">
                            <li><strong>Save time</strong> – Stay at home. We deliver your favorite items.</li>
                            <li><strong>Accessible and Reliable</strong> – Find your favorite items within seconds.</li>
                            
                            <li><strong>Competitive Prices</strong> – The lowest price with the highest quality within the market.</li>

                            
                            <li><strong>Variety of Products</strong> – We make sure to cover all your necessary needs. </li>
                            <!-- <li><strong>Big Data Analytics</strong> – Quickly incubate revolutionary data and effective infomediaries. Credibly transform high standards in convergence via market positioning interfaces.</li> -->
                        </ul>
                        <!-- <div class="action-btns mt-4">
                            <a href="#" class="btn btn-brand-02 mr-3">Get Start Now</a>
                            <a href="#" class="btn btn-outline-brand-02">Learn More</a>
                        </div> -->
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
    export default {
        name: 'About',
        props: {
            isGray: {
                type: Boolean,
                default: false
            }
        }
    }
</script>