<template>
   <div>
      <nav-bar :colored-logo="true" />
      <div class="main">
        <banner />
        <promo />
        <screenshots/>
        <features/>
        <sharePhotos/>
        <testimonial/>
        <faq/>
      </div>
      <site-footer :show-subscribe="true" />
      <copyright />
   </div>
</template>


 <style>

    @font-face {
        font-family: bbr;
        src: url(../../assets/fonts/bbr-cate.ttf);
    }

    .navbar-toggler{
        color: white;
    }

    .vl {
  border-left: 6px solid green;
  height: 500px;
}

::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1; 
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #888; 
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555; 
}

.outer-st{
    background-color: white;
    padding: 3em;
    height: 43vh;
    box-shadow: rgb(99 99 99 / 20%) 0px 2px 8px 0px;
  }

  .re-img{
      margin: auto;
      display: block;
  }

    .desc-st{
        text-align: center;
    }

  .re-out{
      padding-top: 0px;
      padding-bottom: 20px;
  }

  .title-st{
      text-align: center;
      font-weight: bold;
  }

    .lead {
        font-size: 1em !important;
        font-weight: 300;
    }

    .bbrInitial{
        font-family: bbr;
        font-size: 28px;
        line-height: 9px;
    }

    .screenshot-wrap .screenshot-frame {

    width: 305px !important;
    height: 578px !important;

}

    .header nav.navbar {
        padding: 1.3rem 1rem;
        transition: all 300ms ease-in-out;
    }

    .servImg{
        max-width: 100%;
        margin: auto;
        display: block;
    }
      .tab-button {
        padding: 6px 10px;
        border-top-left-radius: 3px;
        border-top-right-radius: 3px;
        border: 1px solid #ccc;
        cursor: pointer;
        background: #f0f0f0;
        margin-bottom: -1px;
        margin-right: -1px;
      }
      .tab-button:hover {
        background: #e0e0e0;
      }
      .tab-button.active {
        background: #e0e0e0;
      }
      .tab {
        border: 1px solid #ccc;
        padding: 10px;
      }

      .nav-item > a{
        width: 17em;
        height: 11em;
        margin: auto;
        box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
        border-radius: 1rem !important;
        transition: all 300ms ease-in-out;
        box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.12);

      }

      .nav-item {
        transition: all 300ms ease-in-out;
      }



    .nav-item:hover {
        transform: translateY(-10px);
    }

      .nav-tabs{
          max-width: 58%;
      }
      
      .marg-body{
           margin-top: 10em;
           margin-bottom: 11em;
      }

.nav-tabs .nav-link.active, .nav-tabs .nav-item.show .nav-link {
    background-color: transparent !important; 
}

.nav-tabs > .nav-item > .active:after{
    content: '';
    background-color: #1f3170 !important;
    position: relative;
    width: 50px;
    display: block;
    margin: auto;
    height: 22px;
    bottom: -177px;
    -webkit-clip-path: polygon(50% 100%, 0 0, 100% 0);
    clip-path: polygon(50% 100%, 0 0, 100% 0);
}

.nav-tabs{
    margin-left: auto !important;
    margin-right: auto !important;
    margin-top: 7em !important;
    margin-bottom: 8em !important; 
    border-bottom: 0px !important;
}



@media(max-width:1864px){
    .nav-tabs {
    max-width: 61%;
}
}

@media(max-width:1776px){
    .nav-tabs {
    max-width: 64%;
}
}

@media(max-width:1689px){
    .nav-tabs {
    max-width: 68%;
}
}

@media(max-width:1589px){
    .nav-tabs {
    max-width: 72%;
}
}

@media(max-width:1501px){
    .nav-tabs {
    max-width: 77%;
}
}


@media(max-width:1404px){
    .nav-tabs {
    max-width: 81%;
}
}

@media(max-width:1334px){
    .nav-tabs {
    max-width: 89%;
}
}


@media(max-width:534px){
    .nav-tabs li:nth-child(1){
    margin-right: 0px;
}

.nav-tabs li:nth-child(2){
    margin-right: 0px;
}

.nav-tabs li:nth-child(3){
    margin-right: 0px;
}

.nav-tabs li:nth-child(4){
    margin-right: 0px;
}

.nav-item > a {
    width: 16em;
    height: 10em;
}

.nav-tabs > .nav-item > .active:after {
    content: '';
    background-color: #1f3170 !important;
    position: relative;
    width: 50px;
    display: block;
    margin: auto;
    height: 22px;
    bottom: -146px;
    -webkit-clip-path: polygon(50% 100%, 0 0, 100% 0);
    clip-path: polygon(50% 100%, 0 0, 100% 0);
}
}


@media(max-width:1214px){
    .nav-tabs li:nth-child(1){
    margin-bottom: 7em;
}

.nav-tabs li:nth-child(2){
    margin-bottom: 7em;
}

.nav-tabs li:nth-child(3){
    margin-bottom: 7em;
}

.nav-tabs li:nth-child(4){
    margin-bottom: 7em;
}
}




    </style>




<script>
    import NavBar from "../../views/commons/NavBar";
    import Banner from "../../views/index-one/Banner";
    import Promo from "../../views/index-one/Promo";
    import SharePhotos from "../../views/index-one/SharePhotos";
    // import Download from "../../views/index-one/Download";
    import Features from "../../views/index-one/Features";
    // import Testim from "../../views/index-one/Testim";
    import Screenshots from "../../views/index-one/Screenshots";
    // import WorkProcess from "../../views/commons/sections/WorkProcess";
    // import CounterSection from "../../views/index-one/CounterSection";
    // import Price from "../../views/index-one/Price";
    // import Team from "../../views/commons/sections/Team";
    import Faq from "../../views/index-one/Faq";
    import Testimonial from "../../views/index-one/Testimonial";
    // import Contact from "../../views/index-one/Contact";
    // import Blog from "../../views/index-one/Blog";
    import SiteFooter from "../../views/commons/SiteFooter";
    import Copyright from "../../views/commons/Copyright";
    // import Screenshots from '../../views/index-one/Screenshots.vue';
    // import Customers from "../../views/commons/sections/Customers";

    // import DAbout from "../../views/index-one/bbrDelivery/Aboutd";
    // import DSharePhotos from "../../views/index-one/bbrDelivery/SharePhotosd";
    // import DDownload from "../../views/index-one/bbrDelivery/Downloadd";
    // import DFeatures from "../../views/index-one/bbrDelivery/Featuresd";
    // import DScreenshots from "../../views/index-one/bbrDelivery/Screenshotsd";
    // import DPrice from "../../views/index-one/bbrDelivery/Priced";
    // import DFaq from "../../views/index-one/bbrDelivery/Faqd";
    // import DTestimonial from "../../views/index-one/bbrDelivery/Testimoniald";
    // import DContact from "../../views/index-one/bbrDelivery/Contactd";
    // import DBlog from "../../views/index-one/bbrDelivery/Blogd";

    // import PAbout from "../../views/index-one/bbrParcel/Aboutp";
    // import PSharePhotos from "../../views/index-one/bbrParcel/SharePhotosp";
    // import PDownload from "../../views/index-one/bbrParcel/Downloadp";
    // import PFeatures from "../../views/index-one/bbrParcel/Featuresp";
    // import PScreenshots from "../../views/index-one/bbrParcel/Screenshotsp";
    // import PPrice from "../../views/index-one/bbrParcel/Pricep";
    // import PFaq from "../../views/index-one/bbrParcel/Faqp";
    // import PTestimonial from "../../views/index-one/bbrParcel/Testimonialp";
    // import PContact from "../../views/index-one/bbrParcel/Contactp";
    // import PBlog from "../../views/index-one/bbrParcel/Blogp";

    // import RAbout from "../../views/index-one/bbrRent/Aboutr";
    // import RSharePhotos from "../../views/index-one/bbrRent/SharePhotosr";
    // import RDownload from "../../views/index-one/bbrRent/Downloadr";
    // import RFeatures from "../../views/index-one/bbrRent/Featuresr";
    // import RScreenshots from "../../views/index-one/bbrRent/Screenshotsr";
    // import RPrice from "../../views/index-one/bbrRent/Pricer";
    // import RFaq from "../../views/index-one/bbrRent/Faqr";
    // import RTestimonial from "../../views/index-one/bbrRent/Testimonialr";
    // import RContact from "../../views/index-one/bbrRent/Contactr";
    // import RBlog from "../../views/index-one/bbrRent/Blogr";

    export default {
        name: "IndexOne",
        components: {
            NavBar,
            Banner,
            Promo,
            SharePhotos,
            // Download,
            Features,
            // Testim,
            Screenshots,
            // WorkProcess,
            // CounterSection,
            // Price,
            Faq,
            Testimonial,
            // Team,
            // Contact,
            // Blog,
            // Customers,
            SiteFooter,
            Copyright
                // Screenshots,

            // DAbout,
            // DSharePhotos,
            // DDownload,
            // DFeatures,
            // DScreenshots,
            // DPrice,
            // DFaq,
            // DTestimonial,
            // DContact,
            // DBlog,

            // PAbout,
            // PSharePhotos,
            // PDownload,
            // PFeatures,
            // PScreenshots,
            // PPrice,
            // PFaq,
            // PTestimonial,
            // PContact,
            // PBlog,

            // RAbout,
            // RSharePhotos,
            // RDownload,
            // RFeatures,
            // RScreenshots,
            // RPrice,
            // RFaq,
            // RTestimonial,
            // RContact,
            // RBlog,
        }
    };


    
</script>


