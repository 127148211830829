<template>
    <section id="about" class="about-us ptb-100 position-relative">
        <div class="container">
            <div class="row align-items-center justify-content-lg-between justify-content-md-center">
                <div class="col-md-5 col-lg-4">
                    <div class="about-content-right">
                        <img src="../../assets/img/app-mobile-image-2.png" alt="about us" class="img-fluid">
                    </div>
                </div>
                <div class="col-md-12 col-lg-7">
                    <div class="about-content-left section-heading">
                        <h2>Why Shopping</h2>

                        <ul class="check-list-wrap pt-3">
                            <li><strong>Save time</strong> – Fast and easy expense reporting. We send you a monthly summary of your rides and expenses.</li>
                            <li><strong>Take control</strong> – Manage all your employees in one place and set clear rules for your business rides.</li>
                            
                            <li><strong>Always there for you</strong> – Get a  fast support, whenever you need it.</li>

                            
                            <li><strong>Ride affordably</strong> – BBR Business rides arrive quickly, and get you and your team around affordably. </li>
                            <!-- <li><strong>Big Data Analytics</strong> – Quickly incubate revolutionary data and effective infomediaries. Credibly transform high standards in convergence via market positioning interfaces.</li> -->
                        </ul>
                        <!-- <div class="action-btns mt-4">
                            <a href="#" class="btn btn-brand-02 mr-3">Get Start Now</a>
                            <a href="#" class="btn btn-outline-brand-02">Learn More</a>
                        </div> -->
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
    export default {
        name: 'About',
        props: {
            isGray: {
                type: Boolean,
                default: false
            }
        }
    }
</script>